import React from "react";
import Grid from "@mui/material/Grid";
import { Fade } from "react-awesome-reveal";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import "./index.scss";
import image from "./photo.jpeg";

const About = () => {
  return (
    <div id="about" className="layout">
      <Fade duration={300}>
        <h2>/ about</h2>
      </Fade>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Fade direction={"up"} cascade duration={200} delay={300}>
            <p>
              I'm currently working in{" "}
              <a
                href="https://kontur.ru"
                target="_blank"
                rel="nofollow noreferrer"
              >
                Kontur
              </a>{" "}
              as a middle plus frontend developer.
            </p>
            <p>
              I have been passionate about everything related to computers since
              childhood. I love <strong>frontend</strong>, doing{" "}
              <strong>beautiful designs</strong>, and doing everything related
              to web development. Also, I like all kinds of creativity.
            </p>
            <p>
              In IT I like things like Unix, Linux, Bash, VIM, Dvorak layout,
              and everything that will speed up my workflow process.
            </p>
            <p>
              In my free time, I like to eat avocado, drink coffee, read books,
              listen to vinyl and watch Liverpool FC games.
            </p>
            <p>
              Here are some technologies and principles I have been working
              with:
            </p>
          </Fade>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ul className="about__list">
                <Fade direction={"up"} cascade duration={300} delay={500}>
                  <li className="about__li">
                    <KeyboardArrowRightIcon className="about__icon" />
                    Javascript, TS
                  </li>
                  <li className="about__li">
                    <KeyboardArrowRightIcon className="about__icon" />
                    React
                  </li>
                  <li className="about__li">
                    <KeyboardArrowRightIcon className="about__icon" />
                    Jest, React Testing Library
                  </li>
                </Fade>
              </ul>
            </Grid>
            <Grid item xs={6}>
              <ul className="about__list">
                <Fade direction={"up"} cascade duration={300} delay={800}>
                  <li className="about__li">
                    <KeyboardArrowRightIcon className="about__icon" />
                    HTML & (S)CSS
                  </li>
                  <li className="about__li">
                    <KeyboardArrowRightIcon className="about__icon" />
                    Clean code & clean architecture, Feature-sliced Design
                  </li>
                  <li className="about__li">
                    <KeyboardArrowRightIcon className="about__icon" />
                    UI & UX
                  </li>
                </Fade>
              </ul>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} display={{ xs: "none", md: "block" }}>
          <Fade>
            <div className="about__image-wrapper">
              <img src={image} alt="It's me :)" className="about__image" />
            </div>
          </Fade>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
